import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const shopApi = createApi({
  reducerPath: 'shopApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Shop'],
  endpoints: builder => ({
    shops: builder.query({
      query: data => ({
        url: `/v1/shop`,
        method: 'GET',
        params: data,
      }),
      providesTags: result => {
        return result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Shop', id })),
              { type: 'Shop', id: 'SHOP_LIST' },
            ]
          : [{ type: 'Shop', id: 'SHOP_LIST' }]
      },
      transformResponse: response => response?.data || response,
    }),
    shop: builder.query({
      query: id => ({
        url: `/v1/shop/${id ? id : ''}`,
        method: 'GET',
        providesTags: (result, error, id) => [{ type: 'Shop', id }],
      }),
      transformResponse: response => response?.data || null,
    }),
    addShop: builder.mutation({
      query: data => ({
        url: `/v1/shop`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: [{ type: 'Shop', id: 'SHOP_LIST' }],
      transformResponse: response => response?.data || null,
    }),
    updateShop: builder.mutation({
      query: data => ({
        url: `/v1/shop/${data?.id ? data.id : ''}`,
        method: 'PUT',
        params: data,
        data: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Shop', id }],
      transformResponse: response => response?.data || response,
    }),
  }),
})

export const {
  useAddShopMutation,
  useShopQuery,
  useUpdateShopMutation,
  useShopsQuery,
} = shopApi

export default shopApi
