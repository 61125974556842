import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (builder) => ({
    orders: builder.query({
      query: (data) => ({
        url: `/v1/order`,
        method: 'GET',
        data: null,
        params: data,
      }),
      transformResponse: (response) => response?.data || response
    }),
    order: builder.query({
      query: (id) => ({
        url: `/v1/order/${id ? id : ''}`,
        method: 'GET',
        data: null,
        params: null,
      }),
      transformResponse: (response) => response?.data || null
    }),
    addOrder: builder.mutation({
      query: (data) => ({
        url: `/v1/order`,
        method: 'POST',
        data: data,
        params: data,
      }),
      transformResponse: (response) => response?.data || null
    }),
    updateOrder: builder.mutation({
      query: (data) => ({
        url: `/v1/order/${data?.id ? data.id : ''}`,
        method: 'PUT',
        // params: data,
        data: data
      }),
      transformResponse: (response) => response?.data || response
    }),
  }),
})

export const { useAddOrderMutation, useOrderQuery, useUpdateOrderMutation, useOrdersQuery } = orderApi

export default orderApi