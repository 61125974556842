import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const oauthApi = createApi({
  reducerPath: 'oauthApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `/v1/oauth/google-signin`,
        method: 'POST',
        data: data,
        params: data,
        // headers: { 'Authorization': `Bearer ${args.blup}` }
      }),
      transformResponse: (response) => response?.data || response
    }),
  }),
})

export const { useLoginMutation } = oauthApi

export default oauthApi