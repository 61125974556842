import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const postApi = createApi({
  reducerPath: 'postApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Post'],
  endpoints: builder => ({
    posts: builder.query({
      query: data => ({
        url: `/v1/post`,
        method: 'GET',
        params: data,
      }),
      providesTags: result => {
        return result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Post', id })),
              { type: 'Post', id: 'POST_LIST' },
            ]
          : [{ type: 'Post', id: 'POST_LIST' }]
      },
      transformResponse: response => response?.data || response,
    }),
    post: builder.query({
      query: id => ({
        url: `/v1/post/${id ? id : ''}`,
        method: 'GET',
        providesTags: (result, error, id) => [{ type: 'Post', id }],
      }),
      transformResponse: response => response?.data || null,
    }),
    addPost: builder.mutation({
      query: data => ({
        url: `/v1/post`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: [{ type: 'Post', id: 'POST_LIST' }],
      transformResponse: response => response?.data || null,
    }),
    updatePost: builder.mutation({
      query: data => ({
        url: `/v1/post/${data?.id ? data.id : ''}`,
        method: 'PUT',
        data: data,
      }),
      transformResponse: response => response?.data || response,
      invalidatesTags: (result, error, { id }) => [{ type: 'Post', id }],
    }),
  }),
})

export const {
  useAddPostMutation,
  usePostQuery,
  useUpdatePostMutation,
  usePostsQuery,
} = postApi

export default postApi
