import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const productUnitApi = createApi({
  reducerPath: 'productUnitApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Unit'],
  endpoints: builder => ({
    productUnits: builder.query({
      query: data => ({
        url: `/v1/product-unit`,
        method: 'GET',
        params: data,
      }),
      providesTags: result => {
        return result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Unit', id })),
              { type: 'Unit', id: 'UNIT_LIST' },
            ]
          : [{ type: 'Unit', id: 'UNIT_LIST' }]
      },
      transformResponse: response => response?.data || response,
    }),
    productUnit: builder.query({
      query: id => ({
        url: `/v1/product-unit/${id ? id : ''}`,
        method: 'GET',
        providesTags: (result, error, id) => [{ type: 'Unit', id }],
      }),
      transformResponse: response => response?.data || null,
    }),
    addProductUnit: builder.mutation({
      query: data => ({
        url: `/v1/product-unit`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: [{ type: 'Unit', id: 'UNIT_LIST' }],
      transformResponse: response => response?.data || null,
    }),
    updateProductUnit: builder.mutation({
      query: data => ({
        url: `/v1/product-unit/${data?.id ? data.id : ''}`,
        method: 'PUT',
        data: data,
      }),
      transformResponse: response => response?.data || response,
      invalidatesTags: (result, error, { id }) => [{ type: 'Unit', id }],
    }),
  }),
})

export const {
  useAddProductUnitMutation,
  useProductUnitQuery,
  useUpdateProductUnitMutation,
  useProductUnitsQuery,
} = productUnitApi

export default productUnitApi
