import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  // data: [],
  // detail: {},
  sidebarShow: true,
}

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    // changeState: (state = initialState, { type, ...rest }) => {
    //   // switch (type) {
    //   //   case 'set':
    //   //     return { ...state, ...rest }
    //   //   default:
    //   //     return state
    //   // }
    //   state.pu
    // }
    changeState(state=initialState, action) {
      // state.push(action.payload)
      console.log(action.payload);
      return state = {
        ...state,
        ...action.payload
      }
    }
  },
})

const menuReducer = menuSlice.reducer

export const menuActions = menuSlice.actions

export default menuReducer
