import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const categoryGroupApi = createApi({
  reducerPath: 'categoryGroupApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  // refetchOnFocus: true,
  // refetchOnMountOrArgChange: true,
  tagTypes: ['CategoryGroup'],
  endpoints: builder => ({
    getListCategoryGroup: builder.query({
      query: data => ({
        url: `/v1/category-group`,
        method: 'GET',
        params: data,
      }),
      providesTags: result => {
        return result
          ? [
              ...result.data.map(({ id }) => ({ type: 'CategoryGroup', id })),
              { type: 'CategoryGroup', id: 'CATEGORY_GROUP_LIST' },
            ]
          : [{ type: 'CategoryGroup', id: 'CATEGORY_GROUP_LIST' }]
      },
      transformResponse: response => response?.data || response,
    }),
    getCategoryGroup: builder.query({
      query: id => ({
        url: `/v1/category-group/${id ? id : ''}`,
        method: 'GET',
        providesTags: (result, error, id) => [{ type: 'CategoryGroup', id }],
      }),
      transformResponse: response => response?.data || null,
    }),
    addCategoryGroup: builder.mutation({
      query: data => ({
        url: `/v1/category-group`,
        method: 'POST',
        data: data,
        params: data,
      }),
      invalidatesTags: [{ type: 'CategoryGroup', id: 'CATEGORY_GROUP_LIST' }],
      transformResponse: response => response?.data || null,
    }),
    updateCategoryGroup: builder.mutation({
      query: data => ({
        url: `/v1/category-group/${data?.id ? data.id : ''}`,
        method: 'PUT',
        params: data,
        data: data,
        // body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'CategoryGroup', id },
      ],
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under its results.
      // invalidatesTags: ['CategoryGroup'],
      // providesTags: ['CategoryGroup'],
      // keepUnusedDataFor: 0,
      // invalidatesTags: (_, _, post) => [{ type: "CategoryGroup", id: post.id }],
      // invalidatesTags: ["CategoryGroup"],
      transformResponse: response => response?.data || response,
    }),

    // getMenu: builder.query({
    // 	query: (type) => ({
    // 		url: `/v1/setting/getMenu${type ? `?${type}` : ''}`,
    // 		method: 'get',
    // 		data: null,
    // 		params: null,
    // 	}),
    // }),
    // getOrther: builder.query({
    // 	query: () => ({
    // 		url: "/v1/setting/getOtherSetting",
    // 		method: "get",
    // 	}),
    // 	transformResponse: (rawResult, meta) => {
    // 		return rawResult.data
    // 	},
    // }),
    // getAllSetting: builder.query({
    // 	query: () => ({
    // 		url: "/v1/setting/all",
    // 		method: "get",
    // 	}),
    // 	transformResponse: (rawResult, meta) => {
    // 		return rawResult.data
    // 	},
    // }),
    // createSetting: builder.query({
    // 	query: (body) => ({
    // 		url: `/v1/setting/create`,
    // 		method: 'POST',
    // 		data: body,
    // 		params: body,
    // 	}),
    // }),
    // updateSetting: builder.query({
    // 	query: ({ id, body }) => ({
    // 		url: `/v1/setting/update?id=${id}`,
    // 		method: 'PUT',
    // 		data: body,
    // 		params: body,
    // 	}),
    // }),
    // deleteSetting: builder.query({
    // 	query: (id) => ({
    // 		url: `/v1/setting/delete?id=${id}`,
    // 		method: 'DELETE',
    // 		data: null,
    // 		params: null,
    // 	}),
    // }),
  }),
})

export const {
  useGetListCategoryGroupQuery,
  useGetCategoryGroupQuery,
  useUpdateCategoryGroupMutation,
  useLazyGetCategoryGroupQuery,
  useLazyGetListCategoryGroupQuery,
  useAddCategoryGroupMutation,
} = categoryGroupApi

export default categoryGroupApi
