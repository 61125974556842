import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const productDiscountApi = createApi({
  reducerPath: 'productDiscountApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Discount'],
  endpoints: builder => ({
    productDiscounts: builder.query({
      query: data => ({
        url: `/v1/product-discount/`,
        method: 'GET',
        params: data,
      }),
      providesTags: result => {
        return result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Discount', id })),
              { type: 'Discount', id: 'DISCOUNT_LIST' },
            ]
          : [{ type: 'Discount', id: 'DISCOUNT_LIST' }]
      },
      transformResponse: response => response?.data || response,
    }),
    allDiscount: builder.query({
      query: data => ({
        url: `/v1/product-discount/all`,
        method: 'GET',
      }),
      transformResponse: response => response?.data || response,
    }),
    productDiscount: builder.query({
      query: id => ({
        url: `/v1/product-discount/${id ? id : ''}`,
        method: 'GET',
        providesTags: (result, error, id) => [{ type: 'Discount', id }],
      }),
      transformResponse: response => response?.data || null,
    }),
    addProductDiscount: builder.mutation({
      query: data => ({
        url: `/v1/product-discount`,
        method: 'POST',
        data: data,
      }),
      // transformResponse: (response) => response?.data || null
      invalidatesTags: [{ type: 'Discount', id: 'DISCOUNT_LIST' }],
    }),
    updateProductDiscount: builder.mutation({
      query: data => ({
        url: `/v1/product-discount/${data?.id ? data.id : ''}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Discount', id }],
      // transformResponse: (response) => response?.data || response
    }),
  }),
})

export const {
  useAddProductDiscountMutation,
  useProductDiscountQuery,
  useUpdateProductDiscountMutation,
  useProductDiscountsQuery,
  useAllDiscountQuery,
} = productDiscountApi

export default productDiscountApi
