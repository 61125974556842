import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // data: [],
  // detail: {},
  baseUrl: process.env.REACT_APP_API_FILE,
  breadcrumbs: [
    {
      name: 'Base',
      path: '',
    },
  ],
}

export const slice = createSlice({
  name: 'chooseImageModal',
  initialState,
  reducers: {
    changeBreadcrumbs(state, action) {
      state.breadcrumbs = action.payload
    },
  },
})

export const selectBaseUrl = state => state[slice.name].baseUrl
export const selectBreadcrumbs = state => state[slice.name].breadcrumbs

export const { changeBreadcrumbs } = slice.actions

const chooseImageModalReducer = slice.reducer

export default chooseImageModalReducer
