import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (builder) => ({
    users: builder.query({
      query: (data) => ({
        url: `/v1/user`,
        method: 'GET',
        data: null,
        params: data,
      }),
      transformResponse: (response) => response?.data || response
    }),
    orderReport: builder.query({
      query: (data) => ({
        url: `/v1/report/order`,
        method: 'GET',
        data: null,
        params: data,
      }),
      transformResponse: (response) => response?.data || response
    }),
    userReport: builder.query({
      query: (data) => ({
        url: `/v1/report/user`,
        method: 'GET',
        data: null,
        params: data,
      }),
      transformResponse: (response) => response?.data || response
    }),
    // report: builder.query({
    //   query: (id) => ({
    //     url: `/v1/report/${id ? id : ''}`,
    //     method: 'GET',
    //     data: null,
    //     params: null,
    //   }),
    //   transformResponse: (response) => response?.data || null
    // }),
    // addreport: builder.mutation({
    //   query: (data) => ({
    //     url: `/v1/report`,
    //     method: 'POST',
    //     data: data,
    //     params: data,
    //   }),
    //   transformResponse: (response) => response?.data || null
    // }),
    // updatereport: builder.mutation({
    //   query: (data) => ({
    //     url: `/v1/report/${data?.id ? data.id : ''}`,
    //     method: 'PUT',
    //     params: data,
    //     data: data
    //   }),
    //   transformResponse: (response) => response?.data || response
    // }),
  }),
})

export const { useOrderReportQuery, useUsersQuery, useUserReportQuery } = reportApi

export default reportApi