// import { useDispatch, useSelector } from 'react-redux';
// import type { TypedUseSelectorHook } from 'react-redux';
// import type {RootState, AppDispatch} from './store';
// import { createApi } from '@reduxjs/toolkit/query';
// import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios from 'axios'
// import type { AxiosRequestConfig, AxiosError } from 'axios';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatch: () => AppDispatch = useDispatch;
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// import axios from 'axios'

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
    async ({ url, method, data, params }) => {
      try {
				// console.log(JSON.parse(url));
				// console.log('url ', `${baseUrl}${url}`);
				// console.log('method ', method);
				// console.log('data ', data);
				// console.log('params ', params);
        // console.log('1212121');
        const result = await axios({ url: `${baseUrl}${url}`, method, data, params })
        console.log(result.data);
        return { data: result.data }
      } catch (axiosError) {
        let err = axiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }

//Use if want to use axios to fetch instead of fetchBaseQuery
// export const axiosBaseQuery =
//   (
//     {baseUrl}: {baseUrl: string} = {baseUrl: ''},
//   ): BaseQueryFn<
//     {
//       url: string;
//       method: AxiosRequestConfig['method'];
//       data?: AxiosRequestConfig['data'];
//       params?: AxiosRequestConfig['params'];
//     },
//     unknown,
//     unknown
//   > =>
//   async ({url, method, data, params}) => {
//     try {
//       const result = await axios({url: baseUrl + url, method, data, params});
//       return {data: result.data};
//     } catch (axiosError) {
//       let err = axiosError as AxiosError;
//       return {
//         error: {
//           status: err.response?.status,
//           data: err.response?.data || err.message,
//         },
//       };
//     }
//   };

//Usage example
// const api = createApi({
//   baseQuery: axiosBaseQuery({
//     baseUrl: 'https://example.com',
//   }),
//   endpoints(build) {
//     return {
//       query: build.query({ query: () => ({ url: '/query', method: 'get' }) }),
//       mutation: build.mutation({
//         query: () => ({ url: '/mutation', method: 'post' }),
//       }),
//     }
//   },
// })

export default axiosBaseQuery
