import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const productInventoryApi = createApi({
  reducerPath: 'productInventoryApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Inventory'],
  endpoints: builder => ({
    productInventorys: builder.query({
      query: data => ({
        url: `/v1/product-inventory`,
        method: 'GET',
        params: data,
      }),
      providesTags: result => {
        return result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Inventory', id })),
              { type: 'Inventory', id: 'INVENTORY_LIST' },
            ]
          : [{ type: 'Inventory', id: 'INVENTORY_LIST' }]
      },
      transformResponse: response => response?.data || response,
    }),
    productInventory: builder.query({
      query: id => ({
        url: `/v1/product-inventory/${id ? id : ''}`,
        method: 'GET',
        providesTags: (result, error, id) => [{ type: 'Inventory', id }],
      }),
      transformResponse: response => response?.data || null,
    }),
    addProductInventory: builder.mutation({
      query: data => ({
        url: `/v1/product-inventory`,
        method: 'POST',
        data: data,
      }),
      transformResponse: response => response?.data || null,
      invalidatesTags: [{ type: 'Inventory', id: 'INVENTORY_LIST' }],
    }),
    updateProductInventory: builder.mutation({
      query: data => ({
        url: `/v1/product-inventory/${data?.id ? data.id : ''}`,
        method: 'PUT',
        data: data,
      }),
      transformResponse: response => response?.data || response,
      invalidatesTags: (result, error, { id }) => [{ type: 'Inventory', id }],
    }),
  }),
})

export const {
  useAddProductInventoryMutation,
  useProductInventoryQuery,
  useUpdateProductInventoryMutation,
  useProductInventorysQuery,
} = productInventoryApi

export default productInventoryApi
