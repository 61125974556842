import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const productCategoryApi = createApi({
  reducerPath: 'productCategoryApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  // refetchOnFocus: true,
  // refetchOnMountOrArgChange: true,
  tagTypes: ['Category'],
  endpoints: builder => ({
    getListProductCategory: builder.query({
      query: () => ({
        url: `/v1/product-category`,
        method: 'GET',
        params: {status: 'all'}
      }),
      providesTags: result => {
        return result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Category', id })),
              { type: 'Category', id: 'CATEGORY_LIST' },
            ]
          : [{ type: 'Category', id: 'CATEGORY_LIST' }]
      },
      transformResponse: response => response?.data || response,
    }),
    getProductCategory: builder.query({
      query: id => ({
        url: `/v1/product-category/${id ? id : ''}`,
        method: 'GET',
        providesTags: (result, error, id) => [{ type: 'Category', id }],
      }),
      transformResponse: response => response?.data || null,
    }),
    addProductCategory: builder.mutation({
      query: data => ({
        url: `/v1/product-category`,
        method: 'POST',
        data: data,
      }),
      // transformResponse: (response) => response?.data || null
      invalidatesTags: [{ type: 'Category', id: 'CATEGORY_LIST' }],
    }),
    updateProductCategory: builder.mutation({
      query: data => ({
        url: `/v1/product-category/${data?.id ? data.id : ''}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Category', id }],
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under its results.
      // invalidatesTags: ['ProductCategory'],
      // providesTags: ['ProductCategory'],
      // keepUnusedDataFor: 0,
      // invalidatesTags: (_, _, post) => [{ type: "ProductCategory", id: post.id }],
      // invalidatesTags: ["ProductCategory"],
      // transformResponse: (response) => response?.data || response
    }),

    // getMenu: builder.query({
    // 	query: (type) => ({
    // 		url: `/v1/setting/getMenu${type ? `?${type}` : ''}`,
    // 		method: 'get',
    // 		data: null,
    // 		params: null,
    // 	}),
    // }),
    // getOrther: builder.query({
    // 	query: () => ({
    // 		url: "/v1/setting/getOtherSetting",
    // 		method: "get",
    // 	}),
    // 	transformResponse: (rawResult, meta) => {
    // 		return rawResult.data
    // 	},
    // }),
    // getAllSetting: builder.query({
    // 	query: () => ({
    // 		url: "/v1/setting/all",
    // 		method: "get",
    // 	}),
    // 	transformResponse: (rawResult, meta) => {
    // 		return rawResult.data
    // 	},
    // }),
    // createSetting: builder.query({
    // 	query: (body) => ({
    // 		url: `/v1/setting/create`,
    // 		method: 'POST',
    // 		data: body,
    // 		params: body,
    // 	}),
    // }),
    // updateSetting: builder.query({
    // 	query: ({ id, body }) => ({
    // 		url: `/v1/setting/update?id=${id}`,
    // 		method: 'PUT',
    // 		data: body,
    // 		params: body,
    // 	}),
    // }),
    // deleteSetting: builder.query({
    // 	query: (id) => ({
    // 		url: `/v1/setting/delete?id=${id}`,
    // 		method: 'DELETE',
    // 		data: null,
    // 		params: null,
    // 	}),
    // }),
  }),
})

export const {
  useGetListProductCategoryQuery,
  useGetProductCategoryQuery,
  useUpdateProductCategoryMutation,
  useLazyGetProductCategoryQuery,
  useLazyGetListProductCategoryQuery,
  useAddProductCategoryMutation,
} = productCategoryApi

export default productCategoryApi
