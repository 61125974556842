export const API_ROOT = process.env.REACT_APP_API_URL

export const setting = {
  getMenu: '/setting/getMenu',
  getList: '/setting/',
  create: '/setting/create',
  update: '/setting/update',
  delete: '/setting/delete',
}

export const product = {
  getList: '/product/',
  create: '/product/create',
  update: '/product/update',
  delete: '/product/delete',
}

export const cart = {
  getList: '/cart/',
  create: '/cart/create',
  update: '/cart/update',
  delete: '/cart/delete',
}

export const customer = {
  getList: '/customer/',
  create: '/customer/create',
  update: '/customer/update',
  delete: '/customer/delete',
}

export const category = {
  getList: '/category/',
  create: '/category/create',
  update: '/category/update',
  delete: '/category/delete',
}

