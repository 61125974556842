import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const productMetadataApi = createApi({
  reducerPath: 'productMetadataApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Metadata'],
  endpoints: builder => ({
    productMetadatas: builder.query({
      query: data => ({
        url: `/v1/product-metadata`,
        method: 'GET',
        params: data,
      }),
      providesTags: result => {
        return result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Metadata', id })),
              { type: 'Metadata', id: 'METADATA_LIST' },
            ]
          : [{ type: 'Metadata', id: 'METADATA_LIST' }]
      },
      transformResponse: response => response?.data || response,
    }),
    productMetadata: builder.query({
      query: id => ({
        url: `/v1/product-metadata/${id ? id : ''}`,
        method: 'GET',
        providesTags: (result, error, id) => [{ type: 'Metadata', id }],
      }),
      transformResponse: response => response?.data || null,
    }),
    addProductMetadata: builder.mutation({
      query: data => ({
        url: `/v1/product-metadata`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: [{ type: 'Metadata', id: 'METADATA_LIST' }],
      transformResponse: response => response?.data || null,
    }),
    updateProductMetadata: builder.mutation({
      query: data => ({
        url: `/v1/product-metadata/${data?.id ? data.id : ''}`,
        method: 'PUT',
        data: data,
      }),
      transformResponse: response => response?.data || response,
      invalidatesTags: (result, error, { id }) => [{ type: 'Metadata', id }],
    }),
  }),
})

export const {
  useAddProductMetadataMutation,
  useProductMetadataQuery,
  useUpdateProductMetadataMutation,
  useProductMetadatasQuery,
} = productMetadataApi

export default productMetadataApi
