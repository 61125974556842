import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Admin'],
  endpoints: builder => ({
    admins: builder.query({
      query: data => ({
        url: `/v1/admin`,
        method: 'GET',
        params: data,
      }),
      providesTags: result => {
        return result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Admin', id })),
              { type: 'Admin', id: 'ADMIN_LIST' },
            ]
          : [{ type: 'Admin', id: 'ADMIN_LIST' }]
      },
      transformResponse: response => response?.data || response,
    }),
    admin: builder.query({
      query: id => ({
        url: `/v1/admin/${id ? id : ''}`,
        method: 'GET',
        providesTags: (result, error, id) => [{ type: 'Admin', id }],
      }),
      transformResponse: response => response?.data || null,
    }),
    addAdmin: builder.mutation({
      query: data => ({
        url: `/v1/admin`,
        method: 'POST',
        data: data,
      }),
      // transformResponse: (response) => response?.data || null
      invalidatesTags: [{ type: 'Admin', id: 'ADMIN_LIST' }],
    }),
    updateAdmin: builder.mutation({
      query: data => ({
        url: `/v1/admin/${data?.id ? data.id : ''}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Admin', id }],
      // transformResponse: (response) => response?.data || response
    }),
  }),
})

export const {
  useAddAdminMutation,
  useAdminQuery,
  useUpdateAdminMutation,
  useAdminsQuery,
} = adminApi

export default adminApi
