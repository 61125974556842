import { combineReducers, configureStore } from '@reduxjs/toolkit'
// import {setupListeners} from "@reduxjs/toolkit/dist/query";
import { settingApi } from './services/setting/setting-service'
// import settingReducer from "./services/setting/setting-slice"
import storage from 'redux-persist/lib/storage'
// import cartReducer from "./services/cart/cart-slice";
// import {productApi} from "./services/product/product-service";
// import productReducer from "./services/product/product-slice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import menuReducer from './services/common/menu-slice'
import { categoryGroupApi } from './services/category-group'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import postApi from './services/post'
import productApi from './services/product'
import productCategoryApi from './services/product-category'
import shopApi from './services/shop'
import productMetadataApi from './services/product-metadata'
import productUnitApi from './services/product-unit'
import orderApi from './services/order'
import productInventoryApi from './services/product-inventory'
import { oauthApi } from './services/oauth'
import adminApi from './services/admin'
import productDiscountApi from './services/product-discount'
import reportApi from './services/report'
import chooseImageModalReducer from 'src/views/common/chooseImageModal/slice'
// import paymentReducer from "./services/payment/payment-slice";
// import {paymentApi} from "./services/payment/payment-service";

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

// const persistedCartReducer = persistReducer(persistConfig, cartReducer);

// const persistedReducer = persistReducer(persistConfig, rootReducer)

const reducers = combineReducers({
  // ...
  // [settingApi.reducerPath]: settingApi.reducer,
  // [categoryGroupApi.reducerPath]: categoryGroupApi.reducer,
  menu: menuReducer,
})
const persistedReducer = persistReducer(persistConfig, reducers)
const store = configureStore({
  // reducer: {
  // 	[settingApi.reducerPath]: settingApi.reducer,
  // 	// setting: settingReducer,
  // 	// cart: persistedCartReducer,
  // 	// product: productReducer,
  // 	// payment: paymentReducer,
  // 	// [paymentApi.reducerPath]: paymentApi.reducer,
  // 	// [settingApi.reducerPath]: settingApi.reducer,
  // 	// [productApi.reducerPath]: productApi.reducer, // thêm reducer được tạo từ api slice
  // },
  // reducer: persistedReducer,
  reducer: {
    // persistReducer
    common: persistedReducer,
    chooseImageModal: chooseImageModalReducer,
    // other reducer
    [settingApi.reducerPath]: settingApi.reducer,
    [categoryGroupApi.reducerPath]: categoryGroupApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [productCategoryApi.reducerPath]: productCategoryApi.reducer,
    [shopApi.reducerPath]: shopApi.reducer,
    [productMetadataApi.reducerPath]: productMetadataApi.reducer,
    [productUnitApi.reducerPath]: productUnitApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [productInventoryApi.reducerPath]: productInventoryApi.reducer,
    [oauthApi.reducerPath]: oauthApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [productDiscountApi.reducerPath]: productDiscountApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    // getDefaultMiddleware().concat([
    // 	settingApi.middleware,
    // 	categoryGroupApi.middleware
    // 	// propertyApi.middleware,
    // 	// cronApi.middleware,
    // 	// contractApi.middleware,
    // 	// errorHandlerMiddleware
    // ])
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      settingApi.middleware,
      categoryGroupApi.middleware,
      postApi.middleware,
      productApi.middleware,
      productCategoryApi.middleware,
      shopApi.middleware,
      productMetadataApi.middleware,
      productUnitApi.middleware,
      orderApi.middleware,
      productInventoryApi.middleware,
      oauthApi.middleware,
      adminApi.middleware,
      productDiscountApi.middleware,
      reportApi.middleware
    ),
  // middleware: (getDefaultMiddleware) =>
  // 	getDefaultMiddleware({
  // 		serializableCheck: {
  // 			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  // 		},
  // 	}).concat(
  // 		settingApi.middleware,
  // 		// productApi.middleware,
  // 		// paymentApi.middleware,
  // 	),
})
export const persistor = persistStore(store)
// Optional, nhưng bắt buộc nếu dùng tính năng refetchOnFocus/refetchOnReconnect
setupListeners(store.dispatch)

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
// export default persistor

// export {persistor, store}
// module.exports {persistor, store}
// export default store
// export {
// 	store
// }

export default store
