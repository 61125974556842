import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/stores/utils'
import { API_ROOT } from '../../../common/network'

export const settingApi = createApi({
  reducerPath: 'settingAPi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Setting'],
  endpoints: builder => ({
    getMenu: builder.query({
      query: type => ({
        url: `/v1/setting/getMenu${type ? `?${type}` : ''}`,
        method: 'get',
      }),
      transformResponse: response => response?.data || response,
    }),
    getOrther: builder.query({
      query: () => ({
        url: '/v1/setting/getOtherSetting',
        method: 'get',
      }),
      //   transformResponse: (rawResult, meta) => {
      //     return rawResult.data
      //   },
      transformResponse: response => response?.data || response,
    }),
    getAllSetting: builder.query({
      query: () => ({
        url: '/v1/setting/all',
        method: 'get',
      }),
      //   transformResponse: (rawResult, meta) => {
      //     return rawResult.data
      //   },
      providesTags: result => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: 'Setting', id })),
              { type: 'Setting', id: 'SETTING_LIST' },
            ]
          : [{ type: 'Setting', id: 'SETTING_LIST' }]
      },
      transformResponse: response => response?.data || response,
    }),
    getSetting: builder.query({
      query: id => ({
        url: `/v1/setting/${id}`,
        method: 'get',
        providesTags: (result, error, id) => [{ type: 'Setting', id }],
      }),
      transformResponse: response => response?.data || response,
    }),
    createSetting: builder.mutation({
      query: body => ({
        url: `/v1/setting`,
        method: 'POST',
        data: body,
      }),
      // transformResponse: (response) => response?.data || response,
      invalidatesTags: [{ type: 'Setting', id: 'SETTING_LIST' }],
    }),
    updateSetting: builder.mutation({
      query: data => ({
        url: `/v1/setting/${data?.id}`,
        method: 'PUT',
        data: data,
      }),
      // transformResponse: (response) => response?.data || response,
      invalidatesTags: (result, error, { id }) => [{ type: 'Setting', id }],
    }),
    deleteSetting: builder.mutation({
      query: data => ({
        url: `/v1/setting/${data?.id}`,
        method: 'DELETE',
      }),
      transformResponse: response => response?.data || response,
    }),
  }),
})

export const {
  useGetMenuQuery,
  useGetOrtherQuery,
  useGetAllSettingQuery,
  useCreateSettingMutation,
  useUpdateSettingMutation,
  useDeleteSettingMutation,
  useGetSettingQuery,
} = settingApi

// export default settingApi
